import React from "react";
import theme from "theme";
import { Theme, Text, Button, Box, Image, Section, LinkBox } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Головна | Velocity Ventures
			</title>
			<meta name={"description"} content={"У Velocity Ventures ми не просто надаємо мотоцикли напрокат - ми даруємо незабутні подорожі. "} />
			<meta property={"og:title"} content={"Головна | Velocity Ventures"} />
			<meta property={"og:description"} content={"У Velocity Ventures ми не просто надаємо мотоцикли напрокат - ми даруємо незабутні подорожі. "} />
			<meta property={"og:image"} content={"https://flectarn.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://flectarn.com/img/3665989.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://flectarn.com/img/3665989.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://flectarn.com/img/3665989.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://flectarn.com/img/3665989.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://flectarn.com/img/3665989.png"} />
			<meta name={"msapplication-TileImage"} content={"https://flectarn.com/img/3665989.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="100px 0 100px 0" md-padding="80px 0 90px 0" quarkly-title="Hero-10">
			<Box
				min-width="100px"
				min-height="100px"
				lg-width="100%"
				display="flex"
				flex-direction="column"
				align-items="center"
				sm-align-items="center"
			>
				<Text
					margin="0px 0px 20px 0px"
					font="normal 700 64px/1.2 --fontFamily-sansTrebuchet"
					sm-font="normal 700 42px/1.2 --fontFamily-sans"
					padding="0px 200px 0px 200px"
					text-align="center"
					color="--darkL1"
					lg-padding="0px 0 0px 0"
					md-font="normal 700 42px/1.2 --fontFamily-sansTrebuchet"
				>
					Velocity Ventures
				</Text>
				<Text
					margin="0px 0px 30px 0px"
					font="normal 400 28px/1.5 --fontFamily-sansHelvetica"
					color="#50555a"
					padding="0px 70px 0px 70px"
					text-align="center"
					lg-padding="0px 0 0px 0"
					md-font="normal 400 22px/1.5 --fontFamily-sansHelvetica"
				>
					Ми дуже раді, що ви завітали до нас! У Velocity Ventures ми не просто надаємо мотоцикли напрокат - ми даруємо незабутні подорожі. Незалежно від того, чи ви досвідчений гонщик, чи новачок, який тільки починає відчувати гострі відчуття від двох коліс, наш автопарк готовий допомогти вам подорожувати з упевненістю та стилем.
				</Text>
				<Button
					margin="0px 0 50px 0px"
					padding="14px 35px 14px 35px"
					background="--color-primary"
					font="normal 400 17px/1.5 --fontFamily-sans"
					sm-margin="0px 0 15px 0px"
					transition="background-color 0.2s ease 0s"
					border-width="2px"
					border-style="solid"
					border-color="--color-primary"
					hover-color="--darkL1"
					hover-background="rgba(63, 36, 216, 0)"
					sm-width="100%"
					href="/contacts"
					type="link"
					text-decoration-line="initial"
					border-radius="5px"
				>
					Контакти
				</Button>
			</Box>
			<Image
				src="https://flectarn.com/img/1.jpg"
				display="block"
				height="600px"
				object-fit="cover"
				border-radius="35px"
				md-height="400px"
				sm-height="300px"
			/>
		</Section>
		<Section padding="80px 0 80px 0" lg-padding="60px 0 60px 0" md-padding="40px 0 40px 0" sm-padding="30px 0 30px 0">
			<Box margin="0px 0px 32px 0px" display="flex" flex-direction="column" align-items="flex-start">
				<Text
					font="--headline2"
					color="--dark"
					text-align="center"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					margin="16px 0px 0px 0px"
				>
					Чому варто подорожувати з нами?
				</Text>
			</Box>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://flectarn.com/img/2.jpg"
						border-radius="24px"
						max-width="100%"
						max-height="456px"
						width="100%"
						object-fit="none"
						lg-max-height="392px"
						sm-max-height="213px"
					/>
				</Box>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" sm-width="100%" lg-padding="0px 0px 0px 0px">
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark">
						Першокласний парк велосипедів: 
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
						Наші велосипеди регулярно обслуговуються та оновлюються, пропонуючи вам надійність та комфорт незалежно від того, куди б не занесла вас пригода.
						</Text>
					</Box>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://flectarn.com/img/3.jpg"
						border-radius="24px"
						max-width="100%"
						max-height="456px"
						width="100%"
						object-fit="none"
						lg-max-height="392px"
						sm-max-height="213px"
					/>
				</Box>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" lg-padding="0px 0px 0px 0px" sm-width="100%">
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark">
						Гнучкі умови прокату:
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
						Від короткої одноденної поїздки до довготривалого туру - ми пропонуємо індивідуальні варіанти оренди відповідно до вашого графіку.
						</Text>
					</Box>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://flectarn.com/img/4.jpg"
						border-radius="24px"
						max-width="100%"
						max-height="456px"
						width="100%"
						object-fit="none"
						lg-max-height="392px"
						sm-max-height="213px"
					/>
				</Box>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" lg-padding="0px 0px 0px 0px" sm-width="100%">
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark">
						Конкурентоспроможні ціни: 
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
						Ми віримо в прозоре ціноутворення без будь-яких прихованих платежів, надаючи вам найкращу ціну за вашу поїздку.
						</Text>
					</Box>
				</Box>
			</LinkBox>
		</Section>
		<Components.Contacts />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});